import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-picture-rounded',
  templateUrl: './picture-rounded.component.html',
  styleUrls: ['./picture-rounded.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class PictureRoundedComponent {
  @Input() pointer = false;
  @Input() clickable = false;
  @Input() content: string = '';
  @Input() defaultPicture: string = '';
  @Input() min = false;

  @Output() click = new EventEmitter();

  constructor(private sanitizer: DomSanitizer) {}

  b64ImageLoad() {
    if (this.content != null && this.content != '') {
      return this.sanitizer.bypassSecurityTrustStyle(`url(${this.content})`);
    } else {
      return this.sanitizer.bypassSecurityTrustStyle(`url(${this.defaultPicture})`);
    }
  }

  clickAction(event: Event) {
    event.stopPropagation();
    this.click.emit(event);
  }
}
